import App from './App.vue'
import { createRouter } from './router'

const app = createApp(App)
const router = createRouter(app)
app.use(router)

// https://router.vuejs.org/guide/migration/#all-navigations-are-now-always-asynchronous
// Will affect the Activity page
router.isReady().then(() => app.mount('#app'))

// Plugin Installations
import { install as auth0Install } from '@/plugins/auth0'
import { install as piniaInstall } from '@/plugins/pinia'
import { install as useHeadInstall } from '@/plugins/useHead'
import { install as vuetifyInstall } from '@/plugins/vuetify'

vuetifyInstall(app)
auth0Install(app)
piniaInstall(app)
useHeadInstall(app)
