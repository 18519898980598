import axios, { AxiosError } from 'axios'
import qs from 'qs'

import {
  catchAndReturnDataMethods,
  catchAndReturnMethods,
  type SafeAxiosInstance,
} from './clientMethodDecorators'

import { auth0 } from '@/plugins/auth0'

export type CreateClientOptions = {
  /** Where on the Brilliant API routes to default to */
  baseRoute?: `/${string}`
}

function interceptUnauthorized(error: AxiosError<any>) {
  // INFO: Any status codes that falls outside the range of 2xx cause this function to trigger
  if (error.response?.status === 401) useAuth0().loginWithRedirect()

  return Promise.reject(error)
}

function interceptAllErrors(e: AxiosError<any>) {
  // Create a combined error message
  const data = e.response?.data

  e.message = [e.message, data?.message, data?.error?.message]
    .filter(item => item != null)
    .join(': ')

  // Pass the error along
  return Promise.reject(e)
}

export async function createClient(options?: CreateClientOptions) {
  await until(auth0.isLoading).toBe(false)

  const accessToken = await auth0.getAccessTokenSilently()
  const client = axios.create({
    baseURL: import.meta.env.VITE_BASE_API_PATH + (options?.baseRoute ?? ''),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  }) as SafeAxiosInstance

  client.interceptors.response.use(undefined, interceptUnauthorized)
  client.interceptors.response.use(undefined, interceptAllErrors)

  client.safeDelete = catchAndReturnMethods(client.delete)
  client.safeGet = catchAndReturnMethods(client.get)
  client.safeHead = catchAndReturnMethods(client.head)
  client.safeOptions = catchAndReturnMethods(client.options)
  client.safePatch = catchAndReturnDataMethods(client.patch)
  client.safePost = catchAndReturnDataMethods(client.post)
  client.safePut = catchAndReturnDataMethods(client.put)

  return client
}

export default createClient
